<template>
 <div class="buzz">
   <h3>
    You are a sad, strange being, and you have my pity.
    Click the Home button below to return to WAVE7.
   </h3>
 </div>
</template>

<script>

export default {
  name: '403',
  setup() {
  },
};
</script>

<style lang="scss" scoped>
.buzz{
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 72px;
  background-image: url(../../public/img/buzz.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  >h3{
    text-align: center;
    color: #252525;
    border-radius: 15px;
    box-shadow: 0px 11.9399995803833px 11.9399995803833px 0px #00000099;
    background: #FFFFFFE5;
    width: 700px;
    max-width: calc(100% - 30px);
    padding: 10px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 150px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 1024px) and (min-width: 600px) and (max-height: 1366px) and (min-height: 961px) and (orientation: portrait) {
  .buzz{
    bottom: 80px;
  }
}

@media screen and (max-width: 599px) and (orientation: portrait),
  screen and (max-height: 500px) and (orientation: landscape){
  .buzz{
    bottom: 268px;
    >h3{
      padding: 15px;
      bottom: 77px;
      left: 15px;
      right: 15px;
      transform: translateX(0);
    }
  }
}
</style>